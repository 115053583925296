@import 'assets/scss/variables.scss';
.defaultStyles {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $border-color;
  background-color: $border-color;
  margin: 1em 0;
  padding: 0;
}
