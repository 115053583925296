@import 'assets/scss/mixins/breakpoints.scss';
.orderSection {
  .flex {
    display: flex;
    flex-direction: column;

    .successMessage {
      display: flex;
      .icon {
        padding-right: 12px;
        span {
          color: #01787a;
          font-size: 18px;
          padding-top: 3px;
          display: inline-flex;
        }
      }
    }
  }
  .approvalHeadingWrapper {
    padding-bottom: 18px;
  }
  .search {
    border: 1px solid $border-color;
    position: relative;
    span {
      background: none;
      font-size: $medium-font;
      color: $dark-gray;
      padding: 5px 8px;
    }
  }
  .orderContent {
    .orderListTable {
      margin-bottom: 3px;
      @include lg {
        margin-bottom: 0;
      }
      ~ div {
        @include lg {
          max-height: 64px;
        }
      }
    }
    > span {
      padding: 0 4px 0 0;
    }
    .contentTable {
      padding-top: 5px;
    }
  }
  button {
    font-weight: bold;
  }
  .orderBtn {
    margin-top: 12px;
    a {
      color: $link-color;
      border: 1px solid $link-color;
      transition: all 0.3s ease;
      padding: 8px 27px;
      line-height: 1.5;
      font-size: $md;
      border-radius: 18px;
      &:hover {
        background: $link-color;
        transition: all 0.3s ease;
        color: $white;
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px #ff00004f,
          inset 0 0 0 1.25px rgba(127, 82, 82, 0.5),
          0 13px 27px -5px rgba(93, 50, 50, 0.1),
          0 8px 16px -8px rgba(0, 0, 0, 0.3),
          0 -6px 16px -6px rgba(0, 0, 0, 0.01);
        height: 100%;
      }
    }
  }
}
.memberShipOrderTableScroll {
  max-height: 80px;
}
