@import 'assets/scss/mixins/breakpoints.scss';
.cardListDetails {
  @include sm {
    margin-right: 10px;
  }
}

.icon {
  font-size: 30px;
  color: #dddddd;
  &:hover {
    color: $light-gray;
  }
}

.fallbackImage {
  font-size: 80px;
}

.hoverAnimation {
  color: $input-border;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $light-gray;
  }
}

.plus {
  font-size: 30px;
  @extend .hoverAnimation;
}

.image {
  height: 80px;
  width: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0 2px;

  max-width: 100%;
  @include sm {
    margin-bottom: 12px;
  }
}

.wrapper {
  height: 80px;
  width: 80px;
  margin: 10px 0 2px;
  > * {
    @extend .hoverAnimation;
  }
}

.label {
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 42px;
  max-width: 120px;

  @include sm {
    max-width: 80px;
  }
}
