@import 'assets/scss/mixins/breakpoints.scss';
.allowanceDashboard {
  flex-direction: column;
  & > * {
    margin-bottom: 10px;
  }
  @include min-sm() {
    flex-direction: row;
    align-items: center;
    & > * {
      margin-bottom: 0;
    }
    & > *:first-child {
      min-width: 25%;
    }
    & > *:nth-child(2) {
      min-width: 25%;
    }
  }
}
