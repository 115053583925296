@import 'assets/scss/variables';
.successMessage {
  display: flex;
  .wrapperIcon {
    padding-right: 12px;
    span {
      color: $success-color;
      font-size: 18px;
      padding-top: 3px;
      display: inline-flex;
    }
  }
}
