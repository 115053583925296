@import 'assets/scss/mixins/breakpoints.scss';
.toggleButton {
  width: 14px;
  height: 20px;
  margin-right: 15px;

  span {
    transition: all 0.3s ease-in-out 0s;
    font-size: 6px;
    opacity: 0.5;
    color: $dark-gray;
    padding-right: 0px;
    transform: rotate(0);
    &[data-togglestate='1'] {
      transform: rotate(-180deg);
    }
  }
  @include xs {
    margin-right: 12px;
  }
}
