@import 'assets/scss/mixins/breakpoints.scss';

.card {
  background: $white;
  padding: 20px;
  width: 100%;
  &.height {
    min-height: 280px;
    @include md {
      min-height: 277px;
    }
    @include sm {
      min-height: 282px;
    }
  }
}
