@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/container.scss';

.siteContent {
  &.marginSm {
    margin: 20px 0;
  }
  &.disc {
    ul {
      padding: 10px 0 0 50px;
      @include xs {
        padding: 10px 0 0 20px;
      }
      li {
        list-style-type: disc;
        color: $dark-gray;
        font-size: $md;
      }
    }
  }
  &.greyText {
    color: $dark-gray;
  }
  @include xs {
    margin: 20px 0 10px;
  }

  [data-contact='contact-us'] {
    width: 1440px;
    [data-contact='quick-help'] {
      > div {
        @include min-lg {
          flex-basis: 25%;
          max-width: 25%;
        }
      }
    }
  }
}
