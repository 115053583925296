@import 'assets/scss/mixins/breakpoints.scss';

.shopNowPreLoginMainWrapper {
  .shopNowPreLoginWrapper {
    border-bottom: 1px solid $panel-border;
    text-align: center;
    @include min-sm {
      width: 50%;
      border-bottom: none;
      padding: 10px 0;
    }
  }
  .loginWrapper {
    @include sm {
      > * {
        padding-left: 0;
      }
    }
    @include min-sm {
      width: 50%;
      padding-top: 0;
      padding: 10px 0;
      border-left: 1px solid $panel-border;
    }
    @include min-md {
      padding: 10px 40px;
    }
  }
  .subHeading {
    margin-bottom: 20px;
    font-weight: bold;
    padding-top: 10px;
  }

  .loginSubHeading {
    margin: 0;
    @include min-sm {
      padding-left: 15px;
    }
  }
}
