@import 'assets/scss/mixins/breakpoints.scss';
.smallAndTransparent {
  background: transparent;
  width: 24px;
  height: 24px;
  &:before {
    color: $dark-gray;
    font-size: $fs-large;
  }
}
