@import 'assets/scss/mixins/breakpoints.scss';
.membershipCardPanel {
  padding: 15px 0;
  background: $white;
  @include md {
    background: $hover-bg;
    padding: 8px 0 25px;
  }
  @include xs {
    padding: 13px 0 28px;
  }
  .mainCardWrapper {
    background: $hover-bg;
    padding: 7px;
    @include md {
      padding: 0;
      background-color: transparent;
    }
    @include sm {
      padding: 0;
    }
    .carouselItems {
      padding: 7px;
      @include xs {
        padding: 0;
      }
    }
  }
}
.slickPrimary {
  background: transparent;
  &:before {
    color: $dark-gray;
    @include sm {
      display: none;
    }
  }
}
