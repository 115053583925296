@import 'assets/scss/mixins/breakpoints.scss';
.productWrapper {
  padding: 30px 0 25px;
  .productContent {
    padding: 5px;
    height: 100%;
  }
}
.btnWrapper {
  display: none;
  @include xs {
    display: block;
    margin-top: 30px;
    button {
      display: block;
      width: 100%;
    }
  }
}
