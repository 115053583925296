@import 'assets/scss/mixins/breakpoints.scss';
@import 'components/shared/HomePageCard/HomePageCard.module.scss';

.promotion {
  display: flex;
  min-height: 212px;
  justify-content: center;

  .flex {
    display: flex;
    flex-direction: column;

    h2 {
      margin-bottom: 15px;
    }
    a {
      margin-top: auto;
      @include sm {
        padding-top: 15px;
      }
    }
    span {
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100px;
    }
  }

  .productImg {
    display: flex;
    align-self: center;
    > figure {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: 0;

      img {
        max-width: 100%;
      }
    }
  }
}
