@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/grid.scss';

.membershipDetail {
  padding: 50px 20px;
  @include md {
    padding: 40px 0 40px;
  }
  @include xs {
    padding: 10px 0;
  }

  .annualCompanySavingsToolTip {
    max-width: 278px;

    .tooltipHeading {
      font-size: $md;
      color: $light-gray;
      line-height: 15px;
      margin-bottom: 5px;
    }

    .subHeading {
      margin-bottom: 3px;
    }
    .divider {
      height: 1px;
      border: 1px solid $border-color;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

.columnForMobile {
  @include sm {
    flex-direction: column;
  }
}
