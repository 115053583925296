@import '../variables.scss';

// Mobile first mixins, please favor usage of those ⬇️
@mixin min-xxs {
  @media (min-width: #{$screen-xxs-max}) {
    @content;
  }
}

@mixin min-xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin min-sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin min-md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin min-lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

//#   ---------------------------
//#   Deprecated mixins (Refrain from using them) ⬇️
//#   ---------------------------

// @deprecated Please refrain from using this mixin and favor mobile first styles
//extra  Small devices
@mixin xs {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

// @deprecated Please refrain from using this mixin and favor mobile first styles
// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// @deprecated Please refrain from using this mixin and favor mobile first styles
@mixin max-md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// @deprecated Please refrain from using this mixin and favor mobile first styles
// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// @deprecated Please refrain from using this mixin and favor mobile first styles
// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}
// @deprecated - Please refrain from using this mixin and favor mobile first styles
//extra  extra Small devices
@mixin xxs {
  @media (max-width: #{$screen-xxs-max}) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
