//colors

$link-color: #cc0000 !default;
$staples-red: $link-color;
$link-color-disabled: #eea5a1 !default;
$form-disabled: #eea5a1 !default;
$link-decoration: $link-color;
$dark-gray: #444444 !default;
$light-gray: #666666 !default;
$medium-gray: #4d4d4d !default;
$very-dark-gray: #595959 !default;
$white: #fff !default;
$border-color: #e7e7e7 !default;
$panel-border: #e4e4e4 !default;
$select-border: #c7c7c7 !default;
$input-border: #dddddd !default;
$hover-bg: #f3f3f3 !default;
$black: #000 !default;
$success-color: #01787a !default;
$card-border: #eaeaea !default;
$zoom-color: #9b9b9b;
$empty-star-color: $border-color;
$dialog-bg: rgba(0, 0, 0, 0.8);
$dialog-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
$dropdown-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18);
$filter-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.09);
$label-checkbox-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
  inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
$checkbox-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
$icon-sort-asc-color: rgb(142, 142, 142);
$icon-sort-desc-color: rgb(68, 68, 68);
$tooltip-bg: #fafcfd;
$tooltip-shadow-color: rgba(0, 0, 0, 0.1);
$support-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
$warning: #ffcd00 !default;
$info-bg: #f7cb2e;
$light-warning: #fff3cd !default;
$danger: $link-color;
$light-danger: #f8d7da !default;
$light-success: #d4edda !default;
$primary: #2a78c6 !default;
$light-primary: #ebf1f7 !default;

$tooltip-bg: #fafcfd;
$tooltip-shadow-color: rgba(0, 0, 0, 0.1);
$cmn-row-border: #dee2e6;

$tooltip-bg: #fafcfd;
$tooltip-shadow-color: rgba(0, 0, 0, 0.1);
$cmn-row-border: #dee2e6;
$box-shadow: rgba(33, 33, 33, 0.2);

$box-shadow-color: rgba(0, 0, 0, 0.11);
$list-border-color: #dadada;
$tool-tip: #2a78c6;
$tooltip-bg: #fafcfd;
$tooltip-shadow-color: rgba(0, 0, 0, 0.1);
$empty-star-color: rgba(216, 216, 216, 0.84706);

:export {
  successColor: $success-color;
  linkColor: $link-color;
  cardBorder: $card-border;
  lightGray: $light-gray;
  borderColor: $border-color;
  inputBorder: $input-border;
}
