@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/breakpoints.scss';

// Inspired by:  https://gist.github.com/tcrammond/99fd936007685dba97b7
// Targets IE 11 and 10 based on
// https://stackoverflow.com/questions/28417056/how-to-target-only-ie-any-version-within-a-stylesheet
@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
// Hides the element when <div></div>
// Has no content
@mixin hide-when-empty {
  &:empty {
    display: none;
  }
}

@mixin decorators {
  &.strong {
    font-weight: bold;
  }
  &.textDecoration,
  &.underline {
    text-decoration: underline;
  }
  &.pointerEvent {
    pointer-events: none;
  }
}

@mixin variants($fontSize) {
  font-size: $fontSize;

  &.primary,
  &.red {
    color: $link-color;
  }

  &.secondary {
    color: $dark-gray;
  }
  &.tertiary {
    color: $medium-gray;
  }
  &.black {
    color: $black;
  }
}

// Text variant
@mixin textVariants($fontSize) {
  font-size: $fontSize;
  &.lightGray {
    color: $light-gray;
  }
  &.strong {
    font-weight: bold;
  }
  &.medium {
    font-family: $font-family-medium;
  }
  &.primary,
  &.red {
    color: $staples-red;
  }
  &.secondary {
    color: $dark-gray;
  }
  &.tertiary {
    color: $medium-gray;
  }
  &.black {
    color: $black;
  }
  &.success {
    color: $success-color;
  }
  &.white {
    color: $white;
  }
  &.green {
    color: $success-color;
  }
  &.blue {
    color: $icon-blue-color;
  }
  &.lineThrough {
    text-decoration: line-through;
  }
}

@mixin userSelectNone {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin commonBox {
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
}
@mixin commonBoxStyling {
  border-radius: 4px;
  border: 1px solid $input-border;
  transition: all 0.3s 0s ease-in-out;
  height: 100%;
}

@mixin hideForPrint {
  @include print() {
    display: none;
  }
}

// As described on
// https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
@mixin visuallyHidden {
  .visuallyHidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

// Inspired from
// https://stackoverflow.com/questions/20626685/better-way-to-set-distance-between-flexbox-items
@mixin gap-x($value: auto) {
  $finalValue: calc($value / 2);
  & {
    display: flex;
    padding-left: $finalValue;
    padding-right: $finalValue;
  }
  & > * {
    flex: 1 1 0;
    margin-left: $finalValue;
    margin-right: $finalValue;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ellipsis-remove {
  white-space: normal;
  overflow: auto;
  text-overflow: clip;
}

@mixin disabled-styles() {
  opacity: 0.4;
  cursor: not-allowed;
}

@mixin flex-width($value: 100%) {
  flex: 0 0 $value;
  max-width: $value;
}

@mixin text-stylings() {
  .cursorPointer {
    cursor: pointer;
  }

  .noWrap {
    white-space: nowrap;
  }
}
