@import './mixins/breakpoints.scss';
.flex {
  display: flex;
  &.columnXs {
    @include sm {
      flex-direction: column;
    }
  }
}

.flexItem {
  flex: 1 1 0;
  padding: 0 3px;
  @include sm {
    flex: none;
  }
}
