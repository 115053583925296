@import 'assets/scss/variables.scss';

.messageSection {
  .successMessage {
    display: flex;
    .icon {
      padding-right: 12px;
      span {
        color: $success-color;
        font-size: 18px;
        padding-top: 3px;
        display: inline-flex;
      }
    }
  }
}

.messageList {
  > div {
    height: 153px;
  }
}

.heading {
  margin-bottom: 15px;
}

// based on
// https://stackoverflow.com/questions/57486526/alternative-to-webkit-line-clamping-for-internet-explorer-11

.clampedLine {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;
  color: $light-gray;
  // For IE and old browsers
  &:after {
    content: '...';
    text-align: right;
    bottom: 0;
    right: 0;
    width: 25%;
    display: block;
    position: absolute;
    height: calc(1em * 1.5);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 75%
    );
  }
}

// If the browser is modern don't use the after, use normal clamping
@supports (-webkit-line-clamp: 1) {
  .clampedLine:after {
    display: none !important;
  }
}

.heightControl {
  -webkit-line-clamp: 2;
  height: calc(1em * 1.5 * 2);
}

.pr15 {
  padding-right: 15px;
}

.clampedLine.inactive {
  a {
    color: inherit;
  }
}
.clampedLine.active {
  white-space: normal;
  height: auto;
  display: block;
  color: inherit;
  &:after {
    display: none;
  }
}

.active .messageList {
  height: 153px;
}
