@import 'assets/scss/mixins/breakpoints.scss';
.budgetCalculator {
  h2,
  .budgetContent {
    padding-bottom: 18px;

    @include lg {
      padding-bottom: 12px;
    }
    .colRow {
      display: flex;
      justify-content: space-between;
      padding-right: 50px;
      @include lg {
        padding-right: 0;
        flex-flow: column;
      }
      .colRight {
        @include lg {
          padding-top: 12px;
        }
      }
    }
  }
}
