// Variables
@import 'assets/scss/colors.module.scss';

//font-weight
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-base: $font-weight-normal !default;

// font-family
$font-family-medium: 'MotivasanMedium';
$font-family-regular: 'MotivasanRegular';

//font-sizes
$xs: 11px !default;
$sm: 12px !default;
$md: 13px !default;
$lg: 14px !default;
$xlg: 15px !default;
$xxLg: 16px !default;

$extra-small-font: 10px;
$small-font: $xs;
$medium-font: $sm;
$body-font: $md;
$fs-large: $xxLg;
$fsXl: 18px;
$fs2xl: 20px;
$fs3xl: 22px;
$fs4xl: 24px;
$fs5xl: 28px;

//line-height
$line-height-base: 1.5 !default;
$body-color: #212529 !default;
$body-bg: $white !default;
$headings-margin-bottom: 10px !default;
$paragraph-margin-bottom: 1rem !default;
//breakpoint

//extra extra small mobile
$screen-xxs-max: 476px;
//mobile
$screen-xs-min: 601px;
//mobile
$screen-xs-max: 600px;
//small tablets
$screen-sm-min: 821px;
// iPad
$screen-md-max: 1023px;
// tablets
$screen-md-min: 1024px;
// laptop
$screen-lg-min: 1280px;

//button-group
$btn-border: $link-color;
$btn-border-radius: 18px;
$icon-grey-color: #3f3e3f;
$icon-green-disabled-color: #98cfd0;
$icon-blue-color: #0875e3;
$thumbnail-border: #979797;
$icon-dark-green-color: #57ae34;

//icon-bg
$icon-restricted: #cc0202;
$text-excluded-items: #9b9b90;

$btn-primary-focus: #ff00004f;
$btn-senary-focus: #6ed9db;

:export {
  lgScreen: $screen-lg-min;
}
