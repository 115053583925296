@import 'assets/scss/mixins/breakpoints.scss';
.promotionCardWrapper {
  margin-top: 10px;
  @include sm {
    margin-top: 20px;
  }
  .mainCardWrapper {
    background: $hover-bg;
    padding: 7px;
    @include sm {
      padding: 7px 7px 25px;
    }
    @include xs {
      padding: 15px 15px 25px;
    }
    .carouselItems {
      padding: 7px;
      @include xs {
        padding: 0;
      }
    }
  }
}
.slickPrimary {
  background: transparent;
  &:before {
    color: #444;
    @include sm {
      display: none;
    }
  }
}
