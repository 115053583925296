@import 'assets/scss/container.scss';
@import 'assets/scss/mixins/utils.scss';

// Remove with peril and risk,
// This was created to fix https://jiraent.staples.com/browse/SCE-2040
// This makes the Banner height change on different view ports

@mixin heights() {
  @include lg {
    min-height: 340px;
  }
  @include md {
    min-height: 286px;
  }

  @include sm {
    min-height: 210px;
  }

  @include xs {
    min-height: 110px;
  }
}
@mixin banner-heights() {
  height: 490px;
  @include lg {
    height: 340px;
  }

  @include md {
    height: 286px;
  }

  @include sm {
    height: 210px;
  }

  @include xs {
    height: 110px;
  }
}

.bannerWrapper {
  @include heights();
  @include ie() {
    @include banner-heights();
  }
  margin-top: 15px;
  min-height: 490px;
  margin-bottom: 15px;

  .skeletonBanner svg {
    @include banner-heights();
  }

  .imgItem {
    @include heights();
    figure {
      margin-bottom: 0;
      display: flex;
      min-height: inherit;
    }
    a {
      width: 100%;
      &:focus {
        outline: 2px solid $dark-gray;
      }
    }
    display: block;
    width: 100%;
    min-height: 490px;
    background-color: lighten($light-gray, 50%);

    img {
      width: 100%;
      text-align: center;
      height: 100%;
      object-fit: contain;
    }
  }
}
.containerMd {
  @include md {
    padding: 0;
  }
}
