@import 'assets/scss/mixins/breakpoints.scss';

.container {
  max-width: 1440px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  &.noPaddingXs {
    @include max-md {
      padding: 0;
    }
  }
}
