@import 'assets/scss/mixins/breakpoints.scss';
.memberDashboardWrapper {
  background: $white;
  margin-bottom: 30px;
  .memberDashboard {
    @include sm {
      overflow-x: auto;
    }
    .memberDashboardScroll {
      @include sm {
        width: 1200px;
      }
    }
  }
}

.listTable {
  div:nth-of-type(1) {
    span {
      display: flex;
      align-items: center;
    }
  }
}
.toggleButton {
  width: 14px;
  height: 20px;
  margin-right: 22px;
  span {
    transition: all 0.3s ease-in-out 0s;
    font-size: 9px;
    opacity: 0.5;
    color: $dark-gray;
    padding-right: 0px;
    &[data-togglestate='0'] {
      transform: rotate(-180deg);
    }
  }
  @include xs {
    margin-right: 12px;
  }
}
